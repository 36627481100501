import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 672.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,672.000000) scale(0.100000,-0.100000)">
          <path d="M3595 6205 c-135 -18 -285 -58 -370 -99 -123 -58 -173 -134 -174
-262 -1 -70 -2 -72 -31 -84 -16 -7 -43 -25 -60 -41 -32 -31 -35 -44 -30 -161
2 -64 0 -69 -29 -98 -45 -45 -66 -124 -65 -245 0 -81 4 -109 22 -149 21 -46
21 -54 12 -187 -10 -143 -2 -292 21 -359 29 -88 115 -154 267 -206 52 -18 96
-35 98 -37 3 -2 -8 -10 -23 -18 -93 -47 -116 -66 -168 -144 -29 -44 -82 -108
-117 -143 l-64 -62 -28 102 c-85 308 -313 1010 -343 1058 -28 44 -320 386
-409 479 -88 93 -115 111 -138 92 -32 -26 -65 -214 -91 -514 -23 -273 -26
-249 74 -558 49 -151 119 -368 156 -484 37 -115 70 -221 75 -235 5 -14 23 -72
40 -129 l32 -104 -31 -67 c-36 -78 -39 -131 -11 -178 11 -18 20 -37 20 -43 0
-17 51 -98 82 -130 14 -16 36 -29 47 -29 11 0 22 -4 26 -9 11 -19 68 -214 160
-551 52 -190 104 -375 115 -412 39 -126 100 -169 249 -176 109 -5 189 9 241
44 30 20 53 25 121 29 101 5 144 18 232 69 70 41 74 48 61 114 -6 30 -7 30
-83 34 l-76 4 -53 -48 c-30 -26 -57 -48 -62 -48 -5 0 -6 15 -3 32 5 35 -23
155 -195 845 -54 216 -96 396 -93 402 3 5 31 27 61 50 l55 41 7 -47 c26 -173
43 -262 54 -289 18 -43 60 -92 95 -110 l29 -15 0 39 c0 22 -11 59 -24 84 -50
94 -87 370 -88 657 -1 200 0 215 18 228 26 21 156 83 171 83 9 0 13 -20 13
-70 0 -124 43 -168 155 -156 l62 6 7 -61 c3 -34 6 -116 6 -183 1 -129 14 -324
24 -333 3 -3 20 -3 38 1 32 6 33 8 30 44 -2 20 -8 148 -15 285 -10 198 -10
247 0 247 7 0 13 -4 13 -9 0 -5 22 -12 49 -16 88 -12 151 41 151 126 0 36 4
49 15 49 26 0 129 -43 161 -67 51 -38 58 -67 50 -218 -8 -174 -23 -221 -89
-290 -29 -30 -56 -55 -60 -55 -5 0 -23 -11 -40 -24 -32 -24 -137 -68 -192 -82
-37 -9 -45 -20 -45 -61 0 -31 1 -32 33 -26 36 7 205 66 236 83 18 9 21 7 27
-14 6 -25 -28 -96 -45 -96 -4 0 -16 -8 -27 -19 -31 -29 -147 -80 -208 -93 -60
-11 -68 -24 -36 -53 26 -24 146 -17 252 15 l77 22 34 -21 c19 -11 43 -21 53
-21 11 -1 -1 -11 -30 -26 -125 -65 -304 -95 -456 -75 -95 12 -238 52 -278 78
-13 8 -26 12 -30 8 -3 -3 2 -79 12 -168 9 -89 28 -263 41 -386 13 -122 26
-226 30 -229 3 -3 14 -1 25 5 10 7 41 15 70 19 47 6 52 5 57 -15 6 -23 25 -28
40 -8 6 6 16 131 22 276 7 145 16 274 21 286 8 20 17 23 82 27 40 2 89 2 108
-2 l35 -5 95 -283 c52 -155 101 -291 108 -302 6 -11 12 -48 12 -82 0 -35 4
-69 8 -75 13 -20 180 -73 249 -80 65 -6 113 11 113 41 0 22 -56 70 -130 112
-76 43 -100 76 -76 103 13 14 14 29 6 81 -60 423 -82 613 -74 657 l9 46 68 14
c102 21 193 68 259 135 48 49 61 69 79 129 25 84 22 168 -11 287 -49 177 -212
442 -309 503 -25 17 -46 32 -46 35 0 3 25 15 56 28 155 63 245 196 256 376 6
90 16 110 58 110 36 0 119 48 169 97 105 105 143 264 95 398 -39 112 -130 195
-214 195 -35 0 -35 0 -35 -45 0 -42 2 -45 27 -45 43 0 91 -40 121 -100 36 -73
37 -170 3 -247 -30 -67 -104 -138 -162 -153 -24 -7 -49 -14 -56 -15 -6 -2 -14
-19 -18 -37 -15 -74 -46 -167 -63 -189 -10 -13 -26 -44 -36 -68 -44 -112 -118
-176 -239 -207 -61 -16 -109 -19 -307 -18 -329 1 -524 26 -700 90 -108 39
-157 72 -182 123 -19 39 -22 66 -26 228 -3 101 -2 183 0 183 3 0 25 -7 49 -16
31 -12 90 -18 198 -20 l155 -5 18 -29 c36 -61 143 -100 215 -81 46 13 78 43
69 66 -5 15 -8 15 -28 1 -35 -25 -118 -21 -159 7 -69 46 -65 111 8 149 44 22
50 30 71 88 12 35 25 90 29 122 5 53 8 58 29 58 23 0 24 -3 24 -80 0 -110 30
-201 86 -257 62 -61 141 -83 320 -90 135 -5 144 -4 189 18 89 44 140 137 152
274 4 55 10 74 22 78 16 4 77 114 68 123 -3 3 -51 21 -107 40 -127 42 -357 70
-490 59 -140 -11 -396 -5 -441 10 -62 22 -274 47 -379 44 l-95 -3 3 35 c8 82
75 182 150 226 60 36 127 36 197 1 124 -63 194 -81 305 -81 58 0 146 7 195 17
298 56 450 60 541 14 28 -15 63 -43 79 -64 32 -41 75 -151 75 -189 0 -32 11
-37 51 -22 55 20 107 83 123 150 10 41 -20 153 -61 227 -40 72 -153 191 -223
235 -223 140 -520 200 -805 160z m-366 -805 c111 -12 221 -43 244 -68 37 -41
13 -198 -40 -270 -32 -43 -71 -62 -147 -69 -91 -8 -260 14 -291 39 -76 59 -95
210 -44 340 9 21 19 28 47 31 72 7 138 7 231 -3z m956 -66 c43 -8 86 -20 94
-28 10 -11 14 -39 14 -103 -1 -137 -41 -224 -116 -252 -55 -21 -238 -8 -329
24 -70 24 -117 107 -117 210 -1 86 14 134 47 146 77 30 243 32 407 3z m-1827
-218 c47 -58 51 -67 42 -92 -9 -23 -14 -26 -42 -20 -17 3 -39 13 -49 22 -16
14 -19 11 -43 -43 -15 -31 -32 -61 -39 -65 -23 -15 -63 -8 -99 17 -20 14 -39
25 -41 25 -3 0 -10 -13 -16 -29 -7 -15 -22 -33 -35 -39 -28 -12 -76 -4 -83 14
-6 16 14 265 22 272 3 3 27 -7 54 -21 27 -15 62 -27 77 -27 57 0 123 63 124
117 1 20 33 -14 128 -131z m130 -246 c6 -19 33 -100 60 -180 27 -80 63 -190
80 -245 17 -55 49 -158 71 -230 58 -189 132 -467 277 -1045 l54 -215 -28 -40
c-40 -57 -118 -112 -175 -125 -51 -11 -164 -12 -211 -1 -27 5 -31 13 -51 86
-12 44 -36 128 -54 186 -32 110 -37 139 -22 139 16 0 97 87 115 124 25 54 14
181 -20 232 -13 19 -24 46 -24 59 0 41 -18 74 -52 95 -37 23 -76 21 -141 -7
-20 -9 -39 -14 -41 -12 -2 2 -25 69 -49 149 -59 189 -106 334 -214 670 l-90
275 47 5 c25 3 58 13 74 23 16 11 35 16 47 12 58 -19 138 1 174 42 15 17 32
23 60 23 22 1 51 7 65 15 29 16 35 12 48 -35z m1025 -601 c12 -7 23 -13 25
-14 2 -2 -6 -21 -17 -44 -12 -23 -21 -59 -21 -86 0 -48 -5 -52 -33 -31 -11 9
-18 26 -17 47 0 19 -3 51 -6 72 -10 58 19 82 69 56z m334 -11 c10 -17 10 -168
0 -168 -5 0 -6 -4 -3 -9 8 -13 -26 -23 -37 -12 -6 6 -12 33 -13 60 -2 27 -10
65 -17 84 -14 33 -14 34 12 44 36 15 49 15 58 1z m-144 -60 c48 -64 -38 -128
-109 -81 -22 14 -25 21 -19 52 10 52 22 62 68 59 30 -2 45 -10 60 -30z m-537
-135 c-3 -10 -16 -64 -30 -121 -33 -133 -49 -165 -105 -210 -25 -20 -47 -40
-49 -44 -11 -23 -30 0 -48 57 l-20 65 83 82 c45 45 99 106 120 135 38 53 59
68 49 36z m1230 -210 c52 -47 120 -269 111 -363 -6 -67 -22 -102 -71 -152 -37
-38 -139 -93 -144 -78 -2 5 8 25 22 43 14 19 29 49 32 67 6 30 8 31 20 15 20
-27 37 -8 31 36 -3 23 -21 60 -43 90 l-38 52 0 132 c0 117 2 136 18 154 23 26
37 26 62 4z m-1932 -209 c24 -9 20 -22 -26 -83 -55 -71 -67 -92 -68 -115 0
-15 11 -8 50 28 55 52 94 61 106 24 5 -18 -4 -32 -57 -79 -72 -65 -74 -91 -3
-35 26 20 53 36 61 36 22 0 15 -51 -13 -91 -61 -88 -164 -112 -164 -38 0 22
-6 32 -25 39 -18 7 -25 17 -25 38 0 16 -9 35 -21 45 -16 14 -19 25 -14 45 18
73 80 152 140 178 35 15 38 16 59 8z m610 -870 c5 -23 3 -42 -5 -52 -29 -39
-77 -76 -129 -100 -47 -22 -70 -26 -150 -27 -123 0 -143 6 -158 48 -7 18 -12
37 -12 40 0 4 29 5 63 1 119 -12 287 37 348 102 30 32 36 30 43 -12z m51 -196
c4 -18 22 -95 41 -172 36 -149 36 -148 -13 -214 -38 -51 -94 -74 -194 -80 -94
-5 -148 7 -179 40 -19 21 -41 86 -80 233 -11 44 -23 88 -26 98 -5 15 4 17 103
17 146 0 256 32 307 89 26 29 33 27 41 -11z"/>
<path d="M3238 5283 c-28 -33 -38 -61 -38 -113 0 -44 5 -56 29 -81 37 -37 65
-37 103 1 29 29 30 34 26 95 -3 50 -9 70 -28 90 -28 29 -70 33 -92 8z"/>
<path d="M4068 5233 c-26 -31 -38 -60 -38 -98 0 -136 131 -169 160 -40 12 52
2 98 -27 129 -28 30 -72 35 -95 9z"/>
<path d="M3199 5560 c-13 -10 -22 -20 -19 -20 3 -1 31 -3 62 -5 31 -2 73 -11
93 -19 31 -13 37 -14 42 -1 15 40 -27 65 -107 65 -33 0 -55 -6 -71 -20z"/>
<path d="M3932 5548 c-22 -22 -13 -38 20 -38 17 0 60 -7 96 -16 35 -9 66 -13
69 -10 10 10 -29 48 -64 62 -40 17 -105 18 -121 2z"/>
<path d="M4572 5184 c-27 -19 -67 -95 -83 -158 -9 -36 -9 -53 0 -73 11 -23 16
-25 61 -21 76 8 107 47 80 99 -13 23 -37 24 -62 2 -24 -22 -32 -12 -16 23 21
49 49 84 66 84 9 0 24 -14 32 -31 20 -41 34 -42 38 -3 2 23 -5 39 -26 62 -33
36 -57 40 -90 16z"/>
<path d="M3838 4715 c-118 -82 -312 -105 -470 -56 -43 14 -81 22 -84 18 -13
-13 6 -37 45 -57 161 -82 457 -60 569 43 26 24 30 72 6 81 -9 3 -16 6 -17 5
-1 -1 -23 -16 -49 -34z"/>
<path d="M3585 3374 c-6 -2 -56 -12 -110 -22 -74 -13 -100 -21 -100 -32 0 -22
81 -34 171 -25 68 6 76 9 85 32 15 39 11 53 -13 52 -13 0 -28 -3 -33 -5z"/>
<path d="M3430 3180 c0 -9 73 -34 136 -46 26 -5 34 -3 34 9 0 19 -21 27 -75
27 -24 0 -55 4 -69 10 -16 6 -26 6 -26 0z"/>
<path d="M3488 1463 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3626 1455 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M2403 1453 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2620 1443 c0 -10 4 -14 8 -8 4 6 11 13 16 18 5 4 2 7 -7 7 -10 0
-17 -8 -17 -17z"/>
<path d="M2365 1440 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2408 1423 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2520 1405 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z"/>
<path d="M2346 1394 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z"/>
<path d="M3281 1405 c-1 -14 51 -146 56 -141 2 2 -10 37 -26 77 -17 41 -30 70
-30 64z"/>
<path d="M2306 1385 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M3060 1363 c0 -12 5 -25 10 -28 13 -8 13 15 0 35 -8 12 -10 11 -10
-7z"/>
<path d="M2511 1354 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2542 1340 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2663 1320 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3231 1344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3211 1324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2562 1300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3381 1293 c-10 -26 -10 -27 4 -9 8 11 15 23 15 28 0 15 -7 8 -19
-19z"/>
<path d="M3411 1303 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M2907 1287 c-3 -8 1 -14 8 -14 7 0 11 6 8 14 -3 7 -6 13 -8 13 -2 0
-5 -6 -8 -13z"/>
<path d="M3087 1283 c-5 -8 20 -50 26 -42 2 2 -2 15 -9 27 -6 13 -14 20 -17
15z"/>
<path d="M3301 1274 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3473 1240 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3591 1264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3630 1236 c0 -38 -3 -45 -22 -49 -20 -4 -19 -4 5 -6 26 -1 27 1 27
49 0 28 -2 50 -5 50 -3 0 -5 -20 -5 -44z"/>
<path d="M2661 1254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2301 1244 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3320 1238 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M3356 1237 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2497 1233 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2641 1214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2915 1201 c-3 -12 -17 -21 -33 -24 -25 -4 -25 -4 4 -6 25 -1 33 4
37 21 8 28 1 36 -8 9z"/>
<path d="M3081 1203 c-1 -12 4 -25 9 -28 12 -7 12 2 0 30 -8 19 -9 19 -9 -2z"/>
<path d="M2388 1194 c12 -8 30 -13 40 -11 13 4 12 5 -5 6 -12 0 -25 6 -28 11
-3 6 -11 10 -18 10 -6 0 -1 -7 11 -16z"/>
<path d="M2496 1194 c-11 -7 -17 -16 -15 -19 3 -3 14 2 25 9 10 8 17 17 14 19
-3 3 -14 -1 -24 -9z"/>
<path d="M2875 1200 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3190 1188 c0 -13 7 -18 28 -17 21 1 22 2 7 6 -11 3 -23 10 -27 17
-5 7 -8 5 -8 -6z"/>
<path d="M3340 1188 c0 -4 9 -8 20 -8 11 0 20 4 20 8 0 4 -9 8 -20 8 -11 0
-20 -4 -20 -8z"/>
<path d="M2355 1180 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M2653 1173 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M2408 1163 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3752 1138 c-7 -7 -12 -17 -12 -24 0 -8 -14 -10 -45 -6 -76 8 -126
-43 -104 -107 9 -26 32 -40 67 -41 17 0 15 39 -3 46 -9 4 -15 19 -15 35 0 28
2 29 45 29 25 0 45 -3 45 -8 0 -22 -51 -206 -72 -257 -25 -63 -45 -77 -72 -50
-23 23 -20 43 7 47 25 3 39 48 14 48 -20 0 -77 -66 -77 -88 0 -26 37 -62 63
-62 30 0 64 25 82 61 14 26 66 187 91 279 8 28 16 36 43 41 18 4 38 12 44 18
9 9 1 10 -32 5 -44 -7 -44 -7 -37 19 7 29 -10 37 -32 15z"/>
<path d="M4024 1096 c-67 -30 -124 -137 -103 -194 8 -20 7 -29 -3 -37 -47 -35
-65 -125 -30 -151 21 -16 83 -18 135 -3 41 11 121 82 115 102 -7 18 -25 19
-40 1 -45 -57 -91 -94 -118 -94 -88 0 -75 137 15 154 44 9 48 36 5 36 -28 0
-30 2 -30 38 0 60 74 153 100 126 23 -24 21 -61 -5 -92 -15 -18 -22 -32 -15
-32 21 0 69 30 80 49 11 21 3 78 -13 99 -12 16 -54 15 -93 -2z"/>
<path d="M4313 1098 c-6 -7 -18 -37 -27 -65 -25 -75 -66 -72 -66 4 0 18 -5 24
-17 21 -20 -4 -28 -37 -19 -74 5 -20 13 -24 42 -24 34 0 36 -2 30 -26 -4 -15
-10 -39 -13 -55 -4 -23 -13 -31 -43 -39 -43 -12 -70 -46 -70 -89 0 -48 45 -61
89 -27 7 6 48 10 90 9 58 -1 66 1 32 6 -36 5 -48 12 -62 38 -18 31 -17 34 6
113 13 45 32 92 43 103 27 29 44 75 37 98 -7 22 -38 25 -52 7z m27 -38 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-127 -253 c7
-19 -21 -80 -33 -72 -14 9 -13 58 2 73 15 15 25 15 31 -1z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
